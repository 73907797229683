<template>
  <div>
    <hero-bar>
      NCM
      <!-- <router-link slot="right" to="/" class="button">
        Dashboard
      </router-link> -->
      <b-button slot="right"
        label="Cadastrar NCM"
        @click="isComponentModalActive = true"
      />
      <b-button slot="right"
        label="Importar planilha"
        @click="showUpload = true"
        type="is-info"
        class="ml-2"
      />
    </hero-bar>

    <upload
      v-if="showUpload"
      @onUpdate="insertPlanilha"
    />

    <section class="section is-main-section">
      <form @submit.prevent="perPageChange">
        <div class="columns">
          <div class="column is-3">
            <b-field class="mb-3" label="Busca">
              <b-input v-model="valorBusca"></b-input>
            </b-field>
          </div>
          <div class="column is-flex is-align-items-self-end">
            <b-field class="mb-3 ml-2">
              <b-button type="is-primary" @click="perPageChange">Buscar</b-button>
            </b-field>
            <b-field class="mb-3 ml-2">
              <b-button @click="limpabusca">Limpar busca</b-button>
            </b-field>
          </div>
        </div>
      </form>

      <card-component
        class="has-table has-mobile-sort-spaced"
        title="NCMs"
        icon="label"
      >
       <b-field class="m-3">
          <b-select v-model="pagination.perPage" @input="perPageChange">
            <option :value="15">15 por página</option>
            <option :value="25">25 por página</option>
            <option :value="50">50 por página</option>
          </b-select>
       </b-field>
        <b-table
          :per-page="pagination.perPage"
          :current-page.sync="pagination.currentPage"
          :backend-pagination="true"
          :total="pagination.total"
          :striped="true"
          :hoverable="true"
          :data="list"
          :loading="isLoading"
          :paginated="true"
          @page-change="pageChange"
        >
          <b-table-column label="NCM" field="NCM" v-slot="props">
            {{ props.row.NCM }}
          </b-table-column>
          <b-table-column label="Preço" field="Preco" v-slot="props">
            {{ formatCurrenyBRL(props.row.Preco) }}
          </b-table-column>
          <b-table-column label="Modo de Cálculo" field="MododeCalculo" v-slot="props">
            {{ props.row.MododeCalculo }}
          </b-table-column>
          <b-table-column label="Última Atualização" field="DTUpdate" v-slot="props">
            {{ formatDate(props.row.DTUpdate) }}
          </b-table-column>
          <b-table-column custom-key="actions" cell-class="is-actions-cell" v-slot="props">
            <div class="buttons is-right">
              <b-button @click="openModalToEdit(props.row)" class="button is-small is-primary">
                <b-icon icon="pencil" size="is-small"/>
              </b-button>
              <b-button @click="confirmDelete({ id: props.row.NCM })" class="button is-small is-danger">
                <b-icon icon="delete" size="is-small"/>
              </b-button>
            </div>
          </b-table-column>
          <template v-if="!isLoading" #empty>Nenhum NCM encontrado</template>

        </b-table>
      </card-component>
    </section>

    <b-modal
        v-model="isComponentModalActive"
        has-modal-card
        @close="reset"
    >
          <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">{{isEditMode ? 'Edição' : 'Cadastro'}} de NCM</p>
            </header>
            <section class="modal-card-body">
              <form @submit.prevent="submit">
                <b-field label="NCM" horizontal>
                  <b-field>
                    <!-- <b-input
                      v-model="form.NCM"
                      placeholder="NCM"
                      name="ncm"
                      required
                      v-mask="'####.##.##'"
                      :masked="false"
                    /> -->

                    <the-mask
                      v-model="form.NCM"
                      class="input"
                      type="text"
                      placeholder="NCM"
                      :mask="['####.##.##']"
                      name="ncm"
                      required
                    />
                  </b-field>
                </b-field>
                <b-field label="Modo de cálculo" horizontal>
                  <b-select
                    v-model="form.MododeCalculo"
                    placeholder="Selecione..."
                    required
                  >
                    <option
                      v-for="(type, index) in modoCalculo"
                      :key="index"
                      :value="type"
                    >
                      {{ type }}
                    </option>
                  </b-select>
                </b-field>
                <b-field label="Preço" horizontal>
                  <b-input
                    v-model="form.Preco"
                    placeholder="0.00"
                    type="number"
                    required
                  />
                </b-field>
              </form>
            </section>
            <footer class="modal-card-foot">
                <b-button type="is-primary is-outlined" @click="reset">Reset</b-button>
                <b-button
                  v-if="isEditMode"
                  label="Editar"
                  type="is-primary"
                  @click="editNCM"
                  :loading="isLoadingForm"
                  native-type="submit"
                />
                <b-button
                  v-else
                  label="Cadastrar"
                  type="is-primary"
                  @click="submit"
                  :loading="isLoadingForm"
                  native-type="submit"
                  :disabled="formHasError"
                />
            </footer>
        </div>
    </b-modal>
    <b-loading :is-full-page="true" v-model="isLoadingPage" :can-cancel="false"></b-loading>
  </div>
</template>

<script>
import mapValues from 'lodash/mapValues'
import CardComponent from '@/components/CardComponent'
import HeroBar from '@/components/HeroBar'
import {
  getNCMs,
  insertNCM,
  editNCM,
  deleteNCM,
  formatImportedList,
  equalsIgnoreOrder,
  notificationError,
  formatDate,
  formatCurrenyBRL,
  formatPaginationObj,
  searchNCM,
  reponseHandler
} from '@/core'
import Upload from '@/components/Upload'

export default {
  name: 'Forms',
  components: {
    HeroBar,
    CardComponent,
    Upload
  },
  data () {
    return {
      isLoading: false,
      isLoadingForm: false,
      list: [],
      pagination: formatPaginationObj(),
      modoCalculo: ['Peso', 'Unidade'],
      isComponentModalActive: false,
      isEditMode: false,
      form: {
        NCM: null,
        Preco: null,
        MododeCalculo: null
      },
      showUpload: false,
      validKeys: ['NCM', 'Preco', 'MododeCalculo'],
      valorBusca: null,
      isLoadingPage: false
    }
  },
  computed: {
    titleStack () {
      return ['Admin', 'Forms']
    },
    formHasError () {
      return Object.values(this.form).some(el => el === null)
    }
  },
  methods: {
    getNCMs (currentPage = this.pagination.currentPage, perPage = this.pagination.perPage) {
      this.isLoading = true
      getNCMs(currentPage, perPage)
        .then(res => {
          this.isLoading = false
          this.list = res.dados
          this.pagination = formatPaginationObj(res)
        })
        .catch(() => {
          this.isLoading = false
          notificationError(this.$buefy.notification)
        })
    },
    formatDate: (v) => formatDate(v),
    formatCurrenyBRL: val => formatCurrenyBRL(val),
    reset () {
      this.form = mapValues(this.form, (item) => {
        if (item && typeof item === 'object') {
          return []
        }
        return null
      })
      this.isEditMode = false
    },
    submit () {
      this.isLoadingForm = true
      const ncm = this.form
      insertNCM(ncm.NCM, ncm.Preco, ncm.MododeCalculo)
        .then(res => {
          reponseHandler(res, () => {
            this.isLoadingForm = false
            this.isComponentModalActive = false
            this.reset()
            this.getNCMs()
          }, () => {
            this.isLoadingForm = false
          })
        })
        .catch(() => {
          this.isLoadingForm = false
          notificationError(this.$buefy.notification)
        })
    },
    openModalToEdit (ncm) {
      this.isEditMode = true
      this.form = {
        NCM: ncm.NCM,
        Preco: ncm.Preco,
        MododeCalculo: ncm.MododeCalculo
      }

      this.isComponentModalActive = true
    },
    editNCM () {
      const ncm = this.form
      editNCM(ncm.NCM, ncm.Preco, ncm.MododeCalculo)
        .then(res => {
          reponseHandler(res, () => {
            this.isComponentModalActive = false
            this.reset()
            this.getNCMs()
          }, () => {
            this.isComponentModalActive = false
          })
        })
        .catch(() => {
          this.isComponentModalActive = false
          notificationError(this.$buefy.notification)
        })
    },
    deleteNCM (id) {
      deleteNCM(id)
        .then(res => {
          this.$buefy.notification.open({
            message: 'NCM removido sucesso!',
            type: 'is-success'
          })
          this.getNCMs()
        })
        .catch(() => {
          notificationError(this.$buefy.notification)
        })
    },
    confirmDelete (ncm) {
      this.$buefy.dialog.confirm({
        title: 'Deletar NCM',
        message: `Tem certeza que deseja deletar ${ncm.id}? Essa ação não pode ser desfeita.`,
        confirmText: 'Deletar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteNCM(ncm.id)
      })
    },
    keysIsValid (rows) { return equalsIgnoreOrder(rows[0], this.validKeys) },
    insertPlanilha (rows) {
      if (this.keysIsValid(rows)) {
        this.isLoadingPage = true
        const list = formatImportedList(rows)
        insertNCM(list)
          .then(res => {
            this.$buefy.notification.open({
              message: 'NCMs cadastrados com sucesso!',
              type: 'is-success'
            })
            this.showUpload = false
            this.getNCMs()
            this.isLoadingPage = false
          })
          .catch(() => {
            this.showUpload = false
            notificationError(this.$buefy.notification)
            this.isLoadingPage = false
          })
      } else {
        this.$buefy.notification.open({
          duration: 7000,
          message: 'Formato de planilha inválido.',
          position: 'is-bottom-right',
          type: 'is-danger',
          hasIcon: true
        })
      }
    },
    buscaNCM (currentPage = this.pagination.currentPage, perPage = this.pagination.perPage) {
      this.isLoading = true
      searchNCM(this.valorBusca, currentPage, perPage)
        .then(res => {
          if (res.result === 0) {
            this.list = res.dados
            this.pagination = formatPaginationObj(res)
          }
          this.isLoading = false
        })
        .catch(() => {
          this.list = []
          this.isLoading = false
          this.pagination = formatPaginationObj()
        })
    },
    limpabusca () {
      this.valorBusca = null
      this.pagination = formatPaginationObj()
      this.getNCMs()
    },
    perPageChange () {
      if (this.valorBusca !== null && this.valorBusca.length) {
        this.buscaNCM()
      } else {
        this.getNCMs()
      }
    },
    pageChange (currentPage) {
      if (this.valorBusca !== null && this.valorBusca.length) {
        this.buscaNCM(currentPage)
      } else {
        this.getNCMs(currentPage)
      }
    }
  },
  created () {
    this.getNCMs()
  }
}
</script>
